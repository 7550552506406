body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Cunia";
  src: url(./assets/fonts/Cunia.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Gordita-Black";
  src: url(./assets/fonts/Gordita_Black.otf);
  font-display: swap;
}
@font-face {
  font-family: "Gordita-Bold";
  src: url(./assets/fonts/Gordita_Bold.otf);
  font-display: swap;
}
@font-face {
  font-family: "Gordita-Light";
  src: url(./assets/fonts/Gordita-Light.otf);
  font-display: swap;
}
@font-face {
  font-family: "Gordita-Medium";
  src: url(./assets/fonts/Gordita_Medium.otf);
  font-display: swap;
}
@font-face {
  font-family: "Gordita-Regular";
  src: url(./assets/fonts/Gordita_Regular.otf);
  font-display: swap;
}
@font-face {
  font-family: "Gordita-RegularItalic";
  src: url(./assets/fonts/Gordita-RegularItalic.otf);
  font-display: swap;
}
@font-face {
  font-family: "Gordita-MediumItalic";
  src: url(./assets/fonts/Gordita-MediumItalic.otf);
  font-display: swap;
}
.green-stroke {
  -webkit-text-stroke: 1px #3f9385;
  text-align: center !important;
}
.green-light-stroke {
  -webkit-text-stroke: 1px #8fe0b3;
  max-width: 80%;
  text-align: center !important;
}

.container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  color: white;
  text-align: center;
}
.container-gsap {
  height: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-responsive-2,
.video-responsive {
  overflow: hidden;
  position: relative;
  height: 200px;
  width: calc(100% - 40px);
  margin: auto;
  background-color: black;
}

.video-responsive #player {
  /* left: 0; */
  /* top: -124px; */
  height: 100%;
  width: 100%;
  /* position: absolute; */
}
.video-responsive img {
  height: 70px;
}
@media (min-width: 600px) {
  .video-responsive-2,
  .video-responsive {
    overflow: hidden;
    position: relative;
    height: 604px;
    width:100%;
    max-width: 1075px;
    max-height: 604px;
    display: flex;
    background-color: black;
  }

  .video-responsive #player {
    /* left: 0; */
    /* top: -2%; */
    height: 100%;
    /* position: absolute; */
  }
}
